import React from 'react';
import weblogo from 'assets/img/logo.png';
import DvsaLogo from 'assets/img/DVSA-EARNED-LOGO-00 (1).png';
import  'components/Footer/Footer.css';
import { Link } from "react-router-dom";
// import Pdf from 'assets/img/TC-TECNORN-TOB-FD-2.pdf';
import XLogo from 'assets/img/x.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GooglePlayDownloadButton from 'data/GooglePlayDownloadButton';
import DownloadButton from "data/DownloadButton.jsx";
import { DropdownButton } from 'react-bootstrap';
import useMediaQuery from 'utils/CustonHooks/UseDeviceResponsiveHook';
import Threads from 'assets/svg/svg';



const Footer=()=> {
  const matches = useMediaQuery("(min-width: 768px)");
  const handleRedirectToPdf = () => {
    window.location.href = 'https://msblue.uk/TC-TECNORN-TOB-FD-2.pdf'; // Replace with your PDF file URL
    };
  return (
     <div>
    <div className='footer'>
      <footer className="footer" role="contentinfo">
        <div className="container MainFooter">
          <div className="row align-items-center" style={{ padding:"0px" }}>
            <div className="col-12 hero-text-image">
              <div className="row" style={{ padding:"0px" }}>
                 
                {!matches && <div className="col-lg-4 footer-logo ">
                  <Link to="/">
                  <img className="img-fluid"  src={weblogo} />
                  </Link>
                  <br/>

                  <h4>
                    <a href="mailto:info@techgv.co.uk">info@techgv.co.uk</a>
                  </h4><br/>
                  <h4><a href="tel:01332323989">01332 323 989</a></h4>
                  {matches && <div >
                  <a href="https://www.facebook.com/TecHGV.Online" className="fa fa-facebook"></a>
                  <a href="https://www.linkedin.com/company/techgv-official/" className="fa fa-linkedin"></a>
                  <a href="https://twitter.com/TecHGV_Official" className="fa"><img   width={15} src={XLogo} /></a> 
                  <a href="https://www.instagram.com/techgv.official/" className="fa fa-instagram"></a>
                  <a href="https://www.quora.com/profile/TecHGV" className="fa fa-quora"></a>
                  <a href="https://www.pinterest.co.uk/techgv/" className="fa fa-pinterest"></a>
                  <a  href="https://www.threads.net/@techgv.official" className="fa threads"><Threads/></a>
                  
                  
                   </div>}
                   
                  
                </div>}
                {matches && <div className="col-lg-4 ">{/* footer-logo */}
                  <Link to="/">
                  <img className="img-fluid"  src={weblogo} />
                  </Link>
                  <br/>

                  <h4>
                    <a href="mailto:info@techgv.co.uk">info@techgv.co.uk</a>
                  </h4><br/>
                  <h4><a href="tel:01332323989">01332 323 989</a></h4>
                  {matches && <div >
                  <a href="https://www.facebook.com/TecHGV.Online" className="fa fa-facebook"></a>
                  <a href="https://www.linkedin.com/company/techgv-official/" className="fa fa-linkedin"></a>
                  <a href="https://twitter.com/TecHGV_Official" className="fa"><img   width={15} src={XLogo} /></a> 
                  <a href="https://www.instagram.com/techgv.official/" className="fa fa-instagram"></a>
                  <a href="https://www.quora.com/profile/TecHGV" className="fa fa-quora"></a>
                  <a href="https://www.pinterest.co.uk/techgv/" className="fa fa-pinterest"></a>
                  <a  href="https://www.threads.net/@techgv.official" className="fa threads"><Threads/></a>
                  
                  
                   </div>}
                   
                  
                </div>}
                {/* <div className="col-lg-4 "> 
                  <img  className="img-fluid3" width={130}   src={DvsaLogo} />
                </div> */}
                {matches && <div className="col-lg-4"> {/* footer-img-fluid3 */}
                  <img  className="img-fluid3 "  style={{ width:"300px" }}    src={DvsaLogo} />
                </div>}
                {!matches && <div className="col-lg-4 footer-img-fluid3"> 
                  <img  className="img-fluid3" width={130} src={DvsaLogo} />
                </div>}
                
                {!matches && <div className='row'><div >
                  <a href="https://www.facebook.com/TecHGV.Online" className="fa fa-facebook"></a>
                  <a href="https://www.linkedin.com/company/techgv-official/" className="fa fa-linkedin"></a>
                  <a href="https://twitter.com/TecHGV_Official" className="fa"><img   width={15} src={XLogo} /></a> 
                  <a href="https://www.instagram.com/techgv.official/" className="fa fa-instagram"></a>
                  <a href="https://www.quora.com/profile/TecHGV" className="fa fa-quora"></a>
                  <a href="https://www.pinterest.co.uk/techgv/" className="fa fa-pinterest"></a>
                  <a  href="https://www.threads.net/@techgv.official" className="fa threads"><Threads/></a>
                  
                   </div></div>}
                
                <div className="col-lg-4">
                <h3>What are you waiting for?</h3>
                <p>Join hundreds of thousands of happy users.</p>
                <div className="row justify-content-md-normal1 ">
                  <div className="col-lg-4 justify-content-md-normal2" style={{ textAlign:"right" }}>
                    {/* <Link to="/"><img  width={120} src={DownloadButton} /></Link> */}
                    <DownloadButton></DownloadButton>
                  </div>
                  <div className="col-lg-4 justify-content-md-normal3"></div>
                  <div className="col-lg-4 justify-content-md-normal2" style={{ textAlign:"left" }}>
                    {/* <Link tp="/"><img  width={120} src={DownloadButton1} /></Link> */}
                    <GooglePlayDownloadButton></GooglePlayDownloadButton>
                  </div>
                </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <div className='footer bottomBar'>
      <div className="container">
        <div className="row justify-content" style={{ paddingTop:"10px" }}>
        
          <div className="col-md-8">
            <p className="copyright">&copy; Copyrights 2024 TecHGV All Rights Reserved Powered By <a href="https://techvezoto.com/"style={{ textDecoration: 'underline' }}>Tech Vezoto</a></p>
          </div>
          <div className="col-md-4">
            <div className="credits "><p><a onClick={handleRedirectToPdf} style={{cursor:"pointer", textDecoration: "underline"}}>Terms of Business </a> &nbsp; <a href="/privacy-policy" style={{ textDecoration: 'underline' }}>Privacy Policy</a></p>
            </div>
          </div>
          
        </div>
      </div>
   
    </div>
  </div>
  )
}


export default Footer;