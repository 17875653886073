import SimpleButton from "components/Buttons/SimpleButton";
import InputWithDropdown from "components/Inputs/InputWithDropdown";
import InputWithTitle from "components/Inputs/InputWithTitle";
import ModalAlert from "components/Modals/Modal";
import { NotRequiredDeviceForm, requestFormEmptyFiled } from "data/FormData";
import { useEffect, useRef, useState } from "react";
import useMediaQuery from "utils/CustonHooks/UseDeviceResponsiveHook";
import { useSendData } from "utils/customHooks";
import { validateEmail } from "utils/method";
import VehicleNumbersDropdown from "./VehicleDropdown";
import VehicleNumbersofInput from "./VehicleNumbersofInput";
import { capitalizeEveryWord } from "utils/method";

function VehicleForm() {
  const [formHandler, setFormHandler] = useSendData();
  const [inputVehicleForms, setInputVehicleForms] = useState([]);
  const matches = useMediaQuery("(min-width: 768px)");
  const [vehicleForm, setVehicleForm] = useState({
    company_name: "",
    email_address: "",
    phone: "",
    o_licence: "",
    address_line: "",
    city: "",
    postal_code: "",
    country: "United Kingdom",
    vehicle_type: "",
    form_select: ["Select", "Single", "Multiple"],
    vehicle_number: "",
    isRequired: false,
    isCheck: false,
    isErrorMessage: "",
  });
  const ScrollInputRef = useRef([]);
  useEffect(() => {
    setVehicleForm({
      ...vehicleForm,
      ...requestFormEmptyFiled,
    });
    setInputVehicleForms([]);
    setFormHandler((valuestoFormHandler) => ({
      ...valuestoFormHandler,
      status: null,
    }));
  }, [formHandler?.status]);

  const onHandleChange = (value, item) => {
    setVehicleForm((prevVehicleForm) => ({
      ...prevVehicleForm,
      [item]: value,
      isRequired: true,
    }));
  };
  const onSelectVehicle = (value) => {
    setVehicleForm({ ...vehicleForm, vehicle_type: value });
    if (value == "Multiple") {
      setInputVehicleForms([]);
    } else {
      setInputVehicleForms({});
      setVehicleForm({
        ...vehicleForm,
        vehicle_number: 1,
        vehicle_type: value,
      });
    }
  };
  const onSelectVehicleNumer = (value) => {
    setVehicleForm({ ...vehicleForm, vehicle_number: value });
  };

  const onChangeInputFields = (value, index, type) => {
    if (type === "Multiple") {
      setInputVehicleForms((prevState) => {
        inputVehicleForms[index] = {
          ...inputVehicleForms[index],
          value: value,
          isChecked: false,
        };
        return inputVehicleForms;
      });
    } else {
      setInputVehicleForms({ value: value, isChecked: false });
    }
  };

  const onCheckBoxValue = (isChecked, index, type) => {
    if (type === "Multiple") {
      setInputVehicleForms((prevState) => {
        inputVehicleForms[index] = {
          ...inputVehicleForms[index],
          value: inputVehicleForms[index]?.value,
          isChecked: isChecked,
        };
        return inputVehicleForms;
      });
    } else {
      setInputVehicleForms({
        value: inputVehicleForms?.value,
        isChecked: isChecked,
      });
    }
  };

  //
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      vehicleForm?.company_name == "" ||
      vehicleForm?.email_address == "" ||
      vehicleForm?.phone == "" ||
      vehicleForm?.o_licence == "" ||
      vehicleForm?.postal_code == "" ||
      // vehicleForm?.address == "" ||
     
      vehicleForm?.city == "" ||
     
      vehicleForm?.country == "" ||
      vehicleForm?.address_line == "" ||
      
     
      !validateEmail(vehicleForm?.email_address)
    ) {
      setVehicleForm({
        ...vehicleForm,
        isRequired: true,
        isErrorMessage: "Please Fill Empty Field",
      });

      ScrollInputRef?.current?.forEach((item) => {
        if (item.value === "") {
          item.scrollIntoView({ behavior: "smooth", block: "center" });
          return;
        }
      });
    } else {
      const data_format = Object.assign({}, vehicleForm, {
        vehicle_numbers: inputVehicleForms,
        status: 1,
        form_name: "Order a Device",
        date: new Date(),
      });
      const formData = new FormData();
      Object?.keys(data_format)?.forEach((item) => {
        formData.append(
          item == "status" || item == "form_name" || item == "vehicle_numbers"
            ? item?.toLowerCase()
            : item?.toLocaleUpperCase(),
          data_format[item]
        );
      });

      if (Array?.isArray(data_format?.vehicle_numbers)) {
        data_format?.vehicle_numbers?.forEach((item, index) => {
          formData.append(
            `VEHICLE_${index + 1}_REGISTRATION_NUMBER`,
            item?.value
          );
          formData.append(
            `VEHICLE_${index + 1}_TELEMETICS`,
            item?.isChecked ? "isChecked" : "Not Checked"
          );
        });
      } else {
        formData.append(
          `VEHICLE_${1}_REGISTRATION_NUMBER`,
          data_format?.vehicle_numbers?.value
        );
        formData.append(
          `VEHICLE_${1}_TELEMETICS`,
          data_format?.vehicle_numbers?.isChecked ? "isChecked" : "Not Checked"
        );
      }
      setFormHandler((valuestoFormHandler) => ({
        ...valuestoFormHandler,
        email_data: data_format,
        form_data: formData,
        isSpinner: true,
      }));
    }
  };
  ///////////Fields Which I dont want from state//////////////
  const FormFields = Object.keys(vehicleForm).filter(
    (fieldName) => !NotRequiredDeviceForm.includes(fieldName)
  );
  /////////////////////////////////////////////////////////////

  return (
    <>
      <div
      className="row"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          borderRadius: "10px",
          padding: "20px 3%",
        }}
      >
        {FormFields.map((item, index) => (
          
          <div className="col-md-6 my-2" >
            <InputWithTitle
              getRef={(ref) => (ScrollInputRef.current[index] = ref)}
              disabled={item === 'country' ||  formHandler?.isSpinner}
              key={index}
              className={"d-flex align-items-center flex-column"}
              textAlign={"start"}
              inputTitle={capitalizeEveryWord(item)}
              placeholder={item ===  'country' ? 'United Kingdom'  : ` Enter ${item.replace('_',' ')}`}
              width={"100%"}
              padding={"10px 10px"}
              border={"1px solid #BFBFBF"}
              textWidth={"100%"}
              value={vehicleForm[item]}
              textPadding={"10px 0px"}
              mesgTextWidth={"100%"}
              type={item.includes("phone") ? "number" : "text"}
              errormesg={
                item == "email_address" &&
                vehicleForm?.email_address !== "" &&
                !validateEmail(vehicleForm["email_address"])
                  ? "Please Enter Verified Email"
                  : vehicleForm[item] == "" && vehicleForm.isErrorMessage
              }
              isErrorMesgShow={vehicleForm.isRequired}
              onChange={(val) => onHandleChange(val.target.value, item)}
            />
          </div>
        ))}
        
        {/* <div className="mb-3">
          <label className="form-label">Zone/Region *</label>
          <input
            type="text"
            className="form-control"
            name="region"
            value="United Kingdom" // Set default value directly
            readOnly // Prevent users from modifying the value
          /> */}
          {/* {isError && formData?.region == "" && (
            <p
              style={{
                textAlign: "left",
                color: "red",
                fontSize: 10,
                margin: "10px 0px",
              }}
            >
              This field is required *
            </p>
          )} */}
        {/* </div> */}


        
        <div className="form-group">
          <InputWithDropdown
            className={
              "d-flex flex-column w-100 justify-content-center align-items-center"
            }
            width={"100%"}
            textWidth={"100%"}
            fontSize={18}
            color={"black"}
            padding={"10px 10px"}
            border={"1px solid #BFBFBF"}
            label={"Vehicles"}
            textPadding={"10px 0px 10px 0px"}
            inputValues={vehicleForm?.form_select}
            onSelect={(val) => onSelectVehicle(val.target.value)}
          />
        </div>

        <>
          {vehicleForm?.vehicle_type == "Multiple" && (
            <VehicleNumbersDropdown
              onChange={(val) => onSelectVehicleNumer(val.target.value)}
            />
          )}
        </>

        {vehicleForm?.vehicle_type == "Single" ||
        vehicleForm?.vehicle_type == "Multiple" ? (
          <VehicleNumbersofInput
            vehicle_number={vehicleForm.vehicle_number}
            onChange={(value, index) =>
              onChangeInputFields(value, index, vehicleForm?.vehicle_type)
            }
            isCheck={vehicleForm?.isCheck}
            onChecked={(value, index) =>
              onCheckBoxValue(value, index, vehicleForm?.vehicle_type)
            }
            values={inputVehicleForms}
          />
        ) : null}

        <SimpleButton
          disabled={formHandler?.isSpinner}
          background={"#7fbcb1"}
          color={"white"}
          text={"Submit"}
          margin={"0px 0px 10px 0px"}
          width={matches ? "130px" : "100%"}
          border={0}
          type={"submit"}
          onSubmit={(event) => handleSubmit(event)}
          isSpinner={formHandler?.isSpinner}
          textAlign={"center"}
          textMargin={"auto"}
        />
      </div>
      <ModalAlert
        isOpenModal={formHandler?.enableModal}
        animationFile={formHandler?.filetype}
        onClose={() => {
          setFormHandler({ ...formHandler, enableModal: false });
        }}
      />
    </>
  );
}

export default VehicleForm;
