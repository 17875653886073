import { useEffect, useState } from "react";
import ErrorLottie from "assets/lottiefiles/Animation - 1716882917673.json";
import SuccessLottie from "assets/lottiefiles/Animation - 1714729928900.json";
import { Send_Email, Send_Form_Data } from "Networking/networking";
export const useSendData = () => {
  const [formHandler, setFormHandler] = useState({
    isSpinner: false,
    filetype: null,
    enableModal: false,
    email_data: {},
    form_data: {},
    status:null
  });

  useEffect(() => {
    if (formHandler?.isSpinner) {
      Send_Request();
    }
  }, [formHandler?.isSpinner]);

  const Send_Request = () => {
    if (formHandler?.email_data != {} && formHandler?.form_data != {}) {
      setFormHandler((prevValues) => ({ ...prevValues, isSpinner: true }));
      Promise.all([
        Send_Email(formHandler?.email_data),
        Send_Form_Data(formHandler?.form_data),
      ])
        .then((values) => {
        
          setFormHandler((prevValues) => ({
            ...prevValues,
            enableModal: true,
            filetype: SuccessLottie,
            status:values[0]?.status
          }));
        })
        .catch((error) => {
          setFormHandler((prevValues) => ({
            ...prevValues,
            enableModal: true,
            
            filetype: ErrorLottie,
            isError:true
          }));
        })
        .finally(() => {
          setFormHandler((prevValues) => ({ ...prevValues, isSpinner: false }));
        });
    }
  };

  return [formHandler, setFormHandler];
};
